import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { GrupoMenu } from 'src/app/core/model';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor } from '@angular/common';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    RouterLinkActive,
    RouterLink,
    MatTooltipModule,

    NzDividerModule,
    NzToolTipModule,
  ],
})
export class MenuListItemComponent {
  @Input() grupo!: GrupoMenu;
  @Input() collapse!: boolean;

  constructor(public router: Router, public sanitizer: DomSanitizer) {}

  get itensDisponiveis() {
    return this.grupo.itens?.filter((item) => item.visible);
  }

  // onItemSelected(item: NavItem) {
  //   if (!item.children || !item.children.length) {
  //     this.router.navigate([item.route]);
  //     if (this.screenWidth < 860)
  //       this.navService.closeNav();
  //   }
  //   if (item.children && item.children.length) {
  //     this.expanded = !this.expanded;
  //   }
  // }
}
