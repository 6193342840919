// Importações do Angular
import { enableProdMode } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { bootstrapApplication } from '@angular/platform-browser';

import localePt from '@angular/common/locales/pt';

// Importações do projeto
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { appConfig } from './app/app.config';

// Se estiver em produção, habilita o modo de produção e configura o Application Insights
if (environment.production) {
  enableProdMode();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: environment.appInsightsConnectionString,
      enableAutoRouteTracking: environment.appInsightsEnableAutoRouteTracking,
    },
  });
  const telemetryInitializer = (envelope) => {
    envelope.tags['ai.cloud.role'] = 'web';
    envelope.tags['ai.cloud.roleInstance'] =
      environment.appInsightsRoleInstance;
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

// Registra o idioma pt-BR
registerLocaleData(localePt);

// Inicializa a aplicação
bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
