import { Routes } from '@angular/router';

import { LoginComponent } from './public/login/login.component';
import { TermoNaoAssinadoComponent } from './termo-nao-assinado/termo-nao-assinado.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'consultas',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.routes').then((r) => r.ADMIN_ROUTES),
  },
  {
    path: 'acessos',
    data: { public: true },
    loadChildren: () =>
      import('./acessos/acessos.routes').then((r) => r.ACESSOS_ROUTES),
  },
  {
    path: 'alertas',
    loadChildren: () =>
      import('./alertas/alertas.routes').then((r) => r.ALERTAS_ROUTES),
  },
  {
    path: 'auditoria',
    loadChildren: () =>
      import('./auditoria/auditoria.routes').then((r) => r.AUDITORIA_ROUTES),
  },
  {
    path: 'consultas',
    loadChildren: () =>
      import('./consultas/consultas.routes').then((r) => r.CONSULTAS_ROUTES),
  },
  {
    path: 'govbr',
    component: LoginComponent,
    data: { nomeModulo: 'Página Inicial', public: true },
  },
  {
    path: 'integracoes',
    loadChildren: () =>
      import('./integracoes/integracoes.routes').then(
        (r) => r.INTEGRACOES_ROUTES,
      ),
  },
  {
    path: 'integracoes-new',
    loadChildren: () =>
      import('./integracoes-new/integracoes-new.routes').then(
        (r) => r.INTEGRACOES_NEW_ROUTES,
      ),
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { nomeModulo: 'Página Inicial', public: true },
  },
  {
    path: 'monitor',
    loadChildren: () =>
      import('./monitor/monitor.routes').then((r) => r.MONITOR_ROUTES),
  },
  {
    path: 'monitor-v2',
    loadChildren: () =>
      import('./monitor-v2/monitor.routes').then((r) => r.MONITOR_ROUTES),
  },
  {
    path: 'p',
    loadChildren: () =>
      import('./public/public.routes').then((r) => r.PUBLIC_ROUTES),
  },
  {
    path: 'pare',
    loadChildren: () => import('./pare/pare.routes').then((r) => r.PARE_ROUTES),
  },
  {
    path: 'passagens',
    loadChildren: () =>
      import('./passagens/passagens.routes').then((r) => r.PASSAGENS_ROUTES),
  },
  {
    path: 'passagens-historico',
    loadChildren: () =>
      import('./passagens-historico/passagens.routes').then(
        (r) => r.PASSAGENS_ROUTES,
      ),
  },
  {
    path: 'passagens-imagens',
    loadChildren: () =>
      import('./passagens-imagens/passagens-imagens.routes').then(
        (r) => r.PASSAGENS_IMAGENS_ROUTES,
      ),
  },
  {
    path: 'passagens-origem-destino',
    loadChildren: () =>
      import('./passagens-origem-destino/passagens-origem-destino.routes').then(
        (r) => r.PASSAGENS_ORIGEM_DESTINO_ROUTES,
      ),
  },
  {
    path: 'solicitar-acesso',
    loadChildren: () =>
      import('./solicitacao-cadastro/solicitacao-cadastro.routes').then(
        (r) => r.SOLICITACAO_CADASTRO_ROUTES,
      ),
  },
  {
    path: 'termo-nao-assinado',
    component: TermoNaoAssinadoComponent,
    data: { nomeModulo: 'Termos e condições de uso', block: true },
  },
  {
    path: 'consulta-facial',
    loadChildren: () =>
      import('./consulta-facial/consulta.facial.routes').then(
        (r) => r.CONSULTA_FACIAL_ROUTES,
      ),
  },
  {
    path: 'usuarios',
    loadChildren: () =>
      import('./usuarios/usuarios.routes').then((r) => r.USUARIOS_ROUTES),
  },

  {
    path: 'passagens-veiculos-apoio',
    loadChildren: () =>
      import('./passagens-veiculos-apoio/passagens-veiculos-apoio.routes').then(
        (r) => r.PASSAGENS_VEICULOS_APOIO_ROUTES,
      ),
  },
  { path: '**', redirectTo: '/' },
];
