<div
    *ngIf="showHeader"
    class="flex h-[55px] items-center justify-between bg-[#052038] pr-2"
>
    <div *ngIf="!block" class="ml-1.5 flex items-center gap-1">
        <button
            nz-button
            nzType="text"
            class="text-gray-200 hover:text-white focus:text-gray-200"
            (click)="toggleCollapse()"
            [disabled]="block"
        >
            <span nz-icon nzType="menu" nzTheme="outline"></span>
        </button>
        <span class="text-base font-light text-gray-50">{{ moduloAtivo }}</span>
    </div>
    @if (block) {
        <div class="flex items-center text-white">
            <img
                src="assets/svg/logo-ab-light.svg"
                alt=""
                class="ml-[17px] size-8"
            />
            <span class="ml-2 grow font-play text-lg">Alerta Brasil</span>
        </div>
    }

    <div class="flex items-center gap-2 text-gray-50">
        <button
            nz-button
            nz-dropdown
            nzType="text"
            nzTrigger="click"
            class="flex items-center gap-2 text-white hover:text-gray-200 focus:text-gray-200"
            [nzDropdownMenu]="userMenu"
            nzPlacement="bottomRight"
        >
            <span
                nz-icon
                nzType="user"
                nzTheme="outline"
                class="mt-1 text-2xl"
            ></span>
            <div class="flex flex-col items-start" *ngIf="!mobileQuery.matches">
                <span class="whitespace-nowrap text-sm font-light">{{
                    nomeUsuario
                }}</span>
                <span class="-mt-[1px] text-2xs font-light">{{
                    siglaOrgao
                }}</span>
            </div>
            <span nz-icon nzType="down"></span>
        </button>
    </div>
</div>

<nz-dropdown-menu #userMenu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item class="h-10" (click)="abrirMeusDados()">
            <span nz-icon nzType="solution"></span>
            <span class="ml-2">Dados pessoais</span>
        </li>
        <li nz-menu-item class="h-10" [routerLink]="'solicitar-acesso'">
            <span nz-icon nzType="credit-card"></span>
            <span class="ml-2">Acessos</span>
        </li>
        <nz-divider class="my-1"></nz-divider>
        <li nz-menu-item class="h-10" (click)="logout()">
            <span nz-icon nzType="api" nzTheme="outline"></span>
            <span class="ml-2">Sair</span>
        </li>
    </ul>
</nz-dropdown-menu>
