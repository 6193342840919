import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.issuer,
  redirectUri: environment.redirectUri,
  clientId: environment.clientId,
  dummyClientSecret: environment.clientSecret,
  responseType: 'code',
  scope: environment.scope,
  showDebugInformation: true,
  timeoutFactor: 0.01,
  logoutUrl: environment.logoutRedirectUri,
  revocationEndpoint: environment.issuer + 'logout',
};
