import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'src/app/auth/auth.service';
import { LogoutService } from 'src/app/auth/logout.service';
import { DialogSpinnerComponent } from 'src/app/core/dialog-spinner/dialog-spinner.component';
import { Arquivo, NotificacaoSistema } from 'src/app/core/model';
import { WebsocketService } from 'src/app/core/websocket.service';

import { MeusDadosComponent } from 'src/app/private/meus-dados/meus-dados.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { RouterLink } from '@angular/router';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NzButtonModule,
    NzIconModule,
    NzDropDownModule,
    NzMenuModule,
    RouterLink,
    NzDividerModule,
  ],
})
export class HeaderToolbarComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  dialogProgressSpinner: MatDialogRef<DialogSpinnerComponent>;
  notificacoes: NotificacaoSistema[] = [];
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  @Input() menuCollapse: boolean;
  @Input() resizeMap: boolean;
  @Input() moduloAtivo: string;
  @Input() block: boolean;
  @Output() collapse = new EventEmitter<boolean>();
  isPageReload = false;

  foto: Arquivo;
  imagemFoto: string | SafeResourceUrl;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    private logoutService: LogoutService,
    public dialog: MatDialog,
    private oauthService: OAuthService,
    private sanitizer: DomSanitizer,
    private websocketService: WebsocketService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  abrirMeusDados() {
    this.dialog.open(MeusDadosComponent, { width: '340px' });
  }

  ngOnInit(): void {
    this.carregarNotificacoes();
  }

  private _preparaFoto() {
    if (this.foto) {
      this.imagemFoto = this.sanitizer.bypassSecurityTrustResourceUrl(
        `data:image/${this.foto.extensao};base64,` + this.foto.dados,
      );
    }
  }

  toggleCollapse() {
    this.menuCollapse = !this.menuCollapse;
    this.collapse.emit(this.menuCollapse);
  }

  closeMenu() {
    this.trigger.closeMenu();
  }

  carregarNotificacoes() {
    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });

    this.notificacoes.push({
      titulo: 'Atualização de cadastro',
      mensagem:
        'Você ainda não atualizou o seu cadastro com as informações obrigatórias. Atualize agora para continuar acessando o sistema.',
      data: new Date('2021-03-05 12:30'),
      enviadoPara: 'Todos',
      lido: false,
    });

    this.notificacoes.push({
      titulo: 'Altere sua senha',
      mensagem:
        'Altere sua senha até o dia 14/4/2021 para não ficar sem acesso ao sistema.',
      data: new Date('2021-03-01 12:30'),
      enviadoPara: 'Você',
      lido: true,
    });
  }

  logout() {
    this.websocketService.disconnect();
    this.logoutService.logoutABAndGovbr(
      'Sessão encerrada com sucesso.',
      undefined,
      'success',
    );
  }

  get usuarioLogado() {
    return this.authService.token && this.oauthService.getIdentityClaims();
  }

  get showHeader() {
    return this.authService.showHeader;
  }

  get nomeSistema() {
    return this.mobileQuery.matches ? 'AB 3.0' : 'Alerta Brasil 3.0';
  }

  get nomeUsuario() {
    const nomes = this.oauthService.getIdentityClaims()['name'].split(' ');
    let primeiroNome = nomes[0].toLowerCase();
    primeiroNome = primeiroNome.charAt(0).toUpperCase() + primeiroNome.slice(1);

    let ultimoNome = nomes[nomes.length - 1].toLowerCase();
    ultimoNome = ultimoNome.charAt(0).toUpperCase() + ultimoNome.slice(1);

    return `${primeiroNome} ${ultimoNome}`;
  }

  get quantidadeNotificacoesNaoLidas() {
    return this.notificacoes.filter((not) => !not.lido).length;
  }

  get siglaOrgao() {
    return this.authService.token.siglaOrgao;
  }

  get isPrf() {
    return this.authService.token.siglaOrgao === 'PRF';
  }
}
