import {
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
} from "@angular/core";

import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CoreModule } from "./core/core.module";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NgIconsModule } from "@ng-icons/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import {
  heroArrowPath,
  heroChatBubbleLeftRight,
  heroMagnifyingGlass,
  heroPlus,
  heroLockClosed,
  heroGlobeAmericas,
  heroMapPin,
  heroChevronDown,
  heroBackward,
  heroArchiveBoxArrowDown,
  heroArrowLeftOnRectangle,
  heroPhoto,
} from "@ng-icons/heroicons/outline";
import {
  heroGlobeAmericasSolid,
  heroLockClosedSolid,
} from "@ng-icons/heroicons/solid";
import { tablerCar, tablerBrandYoutubeFilled } from "@ng-icons/tabler-icons";

import { matAlarmOn, matAlarmOff } from "@ng-icons/material-icons/baseline";
import { WebsocketService } from "./core/websocket.service";
import { HttpImplService } from "./http/http-impl.service";
import { NgxImageCompressService } from "ngx-image-compress";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { HttpInterceptorService } from "./http/http-interceptor.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { NZ_I18N, pt_BR } from "ng-zorro-antd/i18n";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  AimOutline,
  AlertOutline,
  ApartmentOutline,
  ApiOutline,
  AppstoreOutline,
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  BackwardOutline,
  CarOutline,
  CheckOutline,
  CheckCircleOutline,
  ClearOutline,
  CreditCardOutline,
  CrownOutline,
  CopyOutline,
  DownloadOutline,
  DownOutline,
  EnvironmentOutline,
  ExclamationOutline,
  ExclamationCircleTwoTone,
  ExpandOutline,
  FileSearchOutline,
  FilterOutline,
  FlagOutline,
  FullscreenOutline,
  FullscreenExitOutline,
  GlobalOutline,
  HistoryOutline,
  InteractionOutline,
  LinkOutline,
  MailOutline,
  MenuOutline,
  MinusCircleOutline,
  MinusSquareOutline,
  PaperClipOutline,
  PictureOutline,
  PlusOutline,
  PlusSquareOutline,
  PoweroffOutline,
  PushpinOutline,
  QuestionCircleOutline,
  ReloadOutline,
  RollbackOutline,
  SafetyCertificateFill,
  SafetyCertificateOutline,
  SafetyCertificateTwoTone,
  SafetyOutline,
  SaveOutline,
  SearchOutline,
  SendOutline,
  SolutionOutline,
  SoundOutline,
  StopOutline,
  TableOutline,
  UnorderedListOutline,
  UserOutline,
  VideoCameraOutline,
  WarningFill,
  WarningOutline,
} from "@ant-design/icons-angular/icons";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { routes } from "./app.routes";
import { AuthService } from "./auth/auth.service";
import { AuthGuard } from "./auth/auth.guard";
import { LogoutService } from "./auth/logout.service";
import { JwtModule } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";

export function tokenGetter() {
  return localStorage.getItem("token");
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),

    AuthService,
    AuthGuard,
    LogoutService,

    // Importa os providers dos módulos
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      MatBadgeModule,
      MatButtonModule,
      MatCardModule,
      MatDividerModule,
      MatListModule,
      MatInputModule,
      MatIconModule,
      MatMenuModule,
      MatProgressSpinnerModule,
      MatSidenavModule,
      MatTooltipModule,

      CoreModule,

      OAuthModule.forRoot(),
      NzButtonModule,
      NzDividerModule,
      NzDropDownModule,
      NzIconModule.forChild([
        AimOutline,
        AlertOutline,
        ApartmentOutline,

        ApiOutline,
        AppstoreOutline,
        ArrowDownOutline,
        ArrowLeftOutline,
        ArrowRightOutline,
        BackwardOutline,
        CarOutline,
        CheckOutline,
        CheckCircleOutline,
        ClearOutline,
        CopyOutline,
        CreditCardOutline,
        CrownOutline,
        DownloadOutline,
        DownOutline,
        EnvironmentOutline,
        ExclamationOutline,
        ExclamationCircleTwoTone,
        ExpandOutline,
        FileSearchOutline,
        FilterOutline,
        FlagOutline,
        FullscreenOutline,
        FullscreenExitOutline,
        GlobalOutline,
        HistoryOutline,
        InteractionOutline,
        LinkOutline,
        MailOutline,
        MenuOutline,
        MinusCircleOutline,
        MinusSquareOutline,
        PaperClipOutline,
        PictureOutline,
        PlusOutline,
        PlusSquareOutline,
        PoweroffOutline,
        PushpinOutline,
        QuestionCircleOutline,
        ReloadOutline,
        RollbackOutline,
        SafetyCertificateFill,
        SafetyCertificateOutline,
        SafetyCertificateTwoTone,
        SafetyOutline,
        SaveOutline,
        SearchOutline,
        SendOutline,
        SolutionOutline,
        SoundOutline,
        StopOutline,
        TableOutline,
        UnorderedListOutline,
        UserOutline,
        VideoCameraOutline,
        WarningFill,
        WarningOutline,
      ]),
      NgIconsModule.withIcons({
        matAlarmOn,
        matAlarmOff,
        heroArrowPath,
        heroBackward,
        heroMagnifyingGlass,
        heroPhoto,
        heroPlus,
        heroChatBubbleLeftRight,
        heroLockClosed,
        heroLockClosedSolid,
        heroGlobeAmericas,
        heroGlobeAmericasSolid,
        heroMapPin,
        heroChevronDown,
        heroArchiveBoxArrowDown,
        heroArrowLeftOnRectangle,
        tablerCar,
        tablerBrandYoutubeFilled,
      }),

      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: environment.tokenWhitelistedDomains,
          disallowedRoutes: environment.tokenBlacklistedRoutes,
        },
      }),
    ),

    // Providers específicos do projeto

    WebsocketService,
    HttpImplService,
    NgxImageCompressService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: "never",
        appearance: "outline",
        subscriptSizing: "dynamic",
      },
    },
    { provide: NZ_I18N, useValue: pt_BR },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
