<div class="container">
    <div class="wrap-termo" (scroll)="onScroll($event)">
        <p class="subtitle">ALERTA BRASIL</p>
        <span class="title">Termos e condições de uso</span>
        <div *ngIf="carregando" class="lazy-loading"></div>
        <span *ngIf="!carregando" style="white-space: pre-wrap; text-align: justify;"
                [innerHTML]="termoVigente ? sanitizer.bypassSecurityTrustHtml(termoVigente.termo) : ''"></span>
        <!-- <div>
            <p>Para utilizar o sistema, você deve aceitar o Termo de Sigilo e Compromisso, clicando abaixo.</p><br />
        </div> -->
    </div>
    <div style="padding-top: 24px; text-align: right;">
        <button style="width: 233px; height: 55px;" mat-raised-button color="warn" color="primary" (click)="confirmaAceite()" [class.spinner]="aceitando" [matTooltip]="termoLido ? '' : 'É necessário ler todo o documento para aceitar'" [disabled]="aceitando || carregando || !termoLido">Aceitar</button>
    </div>
</div>