/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, OnInit, OnDestroy, signal } from '@angular/core';

import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from 'src/app/auth.config';
import { AuthService } from 'src/app/auth/auth.service';
import { PrivateService } from 'src/app/private/private.service';
import { environment } from 'src/environments/environment';
import { LogoutService } from '../../auth/logout.service';
import { FUNCIONALIDADES } from '../../core/constants';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { UsuarioService } from 'src/app/usuarios/usuario/data-access/usuario.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [NzButtonModule, NzWaveModule],
})
export class LoginComponent implements OnInit, OnDestroy {
  // Injeção de dependências
  private readonly usuarioService = inject(UsuarioService);
  private readonly authService = inject(AuthService);
  private readonly oauthService = inject(OAuthService);
  private readonly logoutService = inject(LogoutService);
  // private readonly dialog = inject(MatDialog);
  private readonly privateService = inject(PrivateService);
  private readonly router = inject(Router);
  private readonly message = inject(NzMessageService);

  private unsubscribe$ = new Subject<void>();
  private loginTimeout: ReturnType<typeof setTimeout> | null = null;

  carregando = signal(false);
  claims: any;

  ngOnInit(): void {
    this.configSso();
    if (this.authService.token) {
      this.router.navigate(['consultas']);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.clearLoginTimeout();
  }

  // Configuração do SSO
  private configSso(): void {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => this.loginApi());
  }

  // Iniciar login com GovBr
  loginGovbr(): void {
    this.carregando.set(true);
    this.oauthService.initCodeFlow();
    this.setLoginTimeout();
  }

  // Login na API após autenticação GovBr
  loginApi(): void {
    this.clearLoginTimeout();

    if (!this.govbrToken) return;

    const { usuario, senha } = this.getCredentials();

    if (!usuario || !senha) {
      this.message.error('CPF e senha são obrigatórios');
      return;
    }

    this.carregando.set(true);

    this.authService
      .login(usuario, senha)
      .then(() => this.handleSuccessfulLogin())
      .catch(this.handleLoginError.bind(this));
  }

  // Obter credenciais do token GovBr
  private getCredentials(): { usuario: string; senha: string } {
    const usuario = encodeURIComponent(this.claims.sub.trim());
    const senha = encodeURIComponent(
      JSON.stringify(this.oauthService.getAccessToken())
        .replace('"', '')
        .trim(),
    );
    return { usuario, senha };
  }

  // Lidar com login bem-sucedido
  private handleSuccessfulLogin(): void {
    if (this.primeiroLogin) {
      this.criarUsuarioNovo();
    } else if (this.authService.getPermissoes().length === 0) {
      this.handleNoPermissions();
    } else {
      this.handleNormalLogin();
    }
  }

  // Criar novo usuário
  private criarUsuarioNovo(): void {
    this.carregando.set(true);
    this.claims.token = this.oauthService.getAccessToken();
    this.usuarioService
      .criarUsuario(this.claims)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.carregando.set(false);
          this.loginApi();
        },
        error: (error) => {
          this.carregando.set(false);
          this.message.error(error);
        },
      });
  }

  // Atualizar dados do usuário
  private atualizarDadosUsuario(): void {
    this.claims.token = this.oauthService.getAccessToken();
    this.usuarioService
      .atualizarUsuario(this.claims)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  // Lidar com usuário sem permissões
  private handleNoPermissions(): void {
    this.authService.showHeader = true;
    this.router.navigate(['solicitar-acesso']);
    this.carregando.set(false);
  }

  // Lidar com login normal
  private handleNormalLogin(): void {
    if (this.usuarioDesatualizado) {
      this.atualizarDadosUsuario();
    }
    this.fetchTermoVigente();
  }

  // Buscar termo vigente
  private fetchTermoVigente(): void {
    this.privateService
      .getTermoVigente()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (resp) => {
          this.authService.termoVigente = resp;
          this.router.navigate(['consultas']);
          this.authService.showHeader = true;
          this.authService.showSidenav = true;
        },
        error: () => {
          this.message.error('Erro ao buscar termo vigente');
          this.carregando.set(false);
        },
      });
  }

  // Lidar com erro de login
  private handleLoginError(erro: any): void {
    this.carregando.set(false);
    this.logoutService.logoutABAndGovbr(
      'Não foi possível realizar o login',
      undefined,
      'error',
    );
    this.message.error(erro);
  }

  // Gerenciamento de timeout de login
  private setLoginTimeout(): void {
    this.loginTimeout = setTimeout(() => {
      if (this.carregando) {
        this.carregando.set(false);
        this.message.error(
          'O serviço de autenticação GovBr encontra-se indisponível no momento. Tente novamente em alguns minutos.',
        );
      }
    }, 5000);
  }

  private clearLoginTimeout(): void {
    if (this.loginTimeout) {
      clearTimeout(this.loginTimeout);
      this.loginTimeout = null;
    }
  }

  // Métodos auxiliares
  logoutGovbr(): void {
    window.location.href = `${environment.issuer}logout?post_logout_redirect_uri=${environment.logoutRedirectUri}`;
  }

  // Getters
  get primeiroLogin(): boolean {
    return this.authService.possuiPermissao(FUNCIONALIDADES.USUARIO.NOVO);
  }

  get usuarioDesatualizado(): boolean {
    return this.authService.possuiPermissao(
      FUNCIONALIDADES.USUARIO.DESATUALIZADO,
    );
  }

  get govbrToken(): any {
    this.claims = this.oauthService.getIdentityClaims();
    return this.claims;
  }
}
