import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'src/app/auth/auth.service';
import { LogoutService } from 'src/app/auth/logout.service';
import { Constants } from 'src/app/constants';
import { GrupoMenu } from 'src/app/core/model';
import { MatDialog } from '@angular/material/dialog';
import { ReportarProblemaComponent } from '../reportar-problema/reportar-problema.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuListItemComponent } from '../menu-list-item/menu-list-item.component';
import { NgIf, NgFor } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzModalService } from 'ng-zorro-antd/modal';
import { N } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MenuListItemComponent,
    MatTooltipModule,
    NgIconsModule,

    NzToolTipModule,
  ],
  providers: [NzModalService],
})
export class MenuComponent implements OnInit {
  modalService = inject(NzModalService);

  private _mobileQueryListener: () => void;
  @Input() collapse: boolean;
  @Output() collapseChange = new EventEmitter<boolean>();
  mobileQuery: MediaQueryList;
  menu: GrupoMenu[] = [];

  constructor(
    private auth: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    private logoutService: LogoutService,
    media: MediaMatcher,
    private oauthService: OAuthService,
    private dialog: MatDialog
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.iniciaTimerValidacaoToken();
    this.menu = this.auth.menu;
  }

  onItemSelected() {
    if (this.mobileQuery.matches) {
      this.collapseChange.emit(false);
    } else {
      this.collapse = true;
      this.collapseChange.emit(this.collapse);
    }
  }

  iniciaTimerValidacaoToken() {
    setTimeout((_) => this.verificaTokenValido(), 10000);
  }

  // reportarProblema() {
  //   this.dialog.open(ReportarProblemaComponent, {
  //     width: '500px',
  //     // Adicione outras configurações necessárias aqui
  //   });
  // }

  reportarProblema() {
    this.modalService.create({
      nzTitle: 'Reportar problema',
      nzContent: ReportarProblemaComponent,
      // nzWidth: 500,
      nzFooter: null,
    });
  }

  verificaTokenValido() {
    var n: string | null = localStorage.getItem('refresh-expires-in');
    // console.log(parseInt(n ? n : "0") - Date.now());
    if (
      Date.now() - parseInt(n ? n : '0') >
      Constants.REFRESH_TOKEN_VALIDITY_MILISSECONDS
    ) {
      this.logoutService.logoutABAndGovbr(
        'Sua sessão expirou. Faça login novamente.',
        undefined,
        'warning'
      );
    } else if (!this.oauthService.getIdentityClaims()) {
      this.logoutService.logoutABAndGovbr();
    } else {
      setTimeout((_) => this.verificaTokenValido(), 10000);
    }
  }
}
