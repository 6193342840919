import { Injectable } from '@angular/core';
import { HttpImplService } from '../../http/http-impl.service';
import { Observable } from 'rxjs';
import { RequisicaoBug } from '../../core/model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportarProblemaService {
  reportarBugUrl = `${environment.apiUrl}faleConosco`;

  constructor(private http: HttpImplService) {}

  public reportarProblema(requisicao: RequisicaoBug): Observable<any> {
    return this.http.post(this.reportarBugUrl, requisicao);
  }
}
