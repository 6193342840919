// Importações do Angular
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';

// Importações de bibliotecas externas
import { MediaMatcher } from '@angular/cdk/layout';
import { OAuthService } from 'angular-oauth2-oidc';

import { NgIf } from '@angular/common';

// Importações de serviços e constantes
import { AuthService } from './auth/auth.service';
import { LogoutService } from './auth/logout.service';
import { Constants } from './constants';

// Importações de componentes
import { HeaderToolbarComponent } from './template/header-toolbar/header-toolbar.component';
import { MenuComponent } from './template/menu/menu.component';
import { Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    MatSidenavModule,
    NgIf,
    MenuComponent,
    HeaderToolbarComponent,
    RouterOutlet,
  ],
})
export class AppComponent {
  // Referência ao elemento sidenav
  @ViewChild('snav', { static: true }) appDrawer: ElementRef;

  // Listener para mudanças na tela
  private _mobileQueryListener: () => void;

  // Variáveis de controle
  menuCollapse = true;
  mobileQuery: MediaQueryList;
  moduloAtivo: string;
  block: boolean;
  primeiraExibicaoMenu = true;
  countExibicaoMenu: number = 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private logoutService: LogoutService,
    private oauthService: OAuthService,
  ) {
    // Verifica se a tela é mobile
    this.mobileQuery = media.matchMedia('(max-width: 600px)');

    // Listener para mudanças na tela
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    // Verifica se o usuário está autenticado
    if (authService.token) {
      this.authService.showHeader = true;
      this.authService.showSidenav = true;
    }

    // Verifica a rota atual
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        if (data.state.root.children[0].children.length > 0) {
          this.moduloAtivo =
            data.state.root.firstChild?.firstChild?.data['nomeModulo'];
          this.block = data.state.root.firstChild?.firstChild?.data['block'];
        } else {
          this.moduloAtivo = data.state.root.firstChild?.data['nomeModulo'];
          this.block = data.state.root.firstChild?.data['block'];
        }
        if (data.url === '/solicitar-acesso') {
          this.block = !this.authService.possuiUsuarioOrgao;
        }
      }
    });
  }

  // Remove o listener para mudanças na tela
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  // Verifica se o token é válido
  async verificaTokenValido() {
    const n: string | null = localStorage.getItem('refresh-expires-in');
    if (
      Date.now() - parseInt(n ? n : '0') >
      Constants.REFRESH_TOKEN_VALIDITY_MILISSECONDS
    ) {
      this.logoutService.logoutABAndGovbr(
        'Sua sessão expirou. Faça login novamente.',
        undefined,
        'warning',
      );
    } else if (!this.oauthService.getIdentityClaims()) {
      this.logoutService.logoutABAndGovbr();
    }
  }

  /**
   * Handles the opening of the menu.
   * @param open - A boolean indicating whether the menu should be opened or closed.
   */
  aberturaDoMenuAlterada(open) {
    const navigationType = (
      window.performance.getEntriesByType(
        'navigation',
      )[0] as PerformanceNavigationTiming
    ).type;

    const isPageReload = navigationType === 'reload';

    this.countExibicaoMenu++;
    this.primeiraExibicaoMenu = this.countExibicaoMenu == 1;

    this.verificaTokenValido();

    if (this.mobileQuery.matches) this.menuCollapse = open;
    else if (!this.primeiraExibicaoMenu) this.menuCollapse = open;
    else if (isPageReload) this.menuCollapse = isPageReload;
  }

  // Retorna se o usuário está autenticado
  get usuarioLogado() {
    return this.authService.token;
  }

  // Retorna se o header deve ser exibido
  get showHeader() {
    return this.authService.showHeader;
  }

  // Retorna se o sidenav deve ser exibido
  get showSidenav() {
    return this.authService.showSidenav;
  }
}
