/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  /**
   * Intercepta todas as requisições HTTP saintes
   * @param request A requisição HTTP original
   * @param next O próximo manipulador na cadeia de interceptadores
   * @returns Um Observable com a resposta HTTP
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    const clonedRequest = this.cloneRequest(request);
    return next.handle(clonedRequest);
  }

  /**
   * Clona a requisição original e adiciona headers de autenticação se necessário
   * @param request A requisição HTTP original
   * @returns Uma nova requisição HTTP com headers modificados, se aplicável
   */
  private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getToken();

    if (this.shouldSkipInterception(request, token)) {
      return request;
    }

    if (token === null) {
      // Se o token for null, retornamos a requisição original sem modificações
      return request;
    }

    const headers = this.buildHeaders(request, token);
    return request.clone({ setHeaders: headers });
  }

  /**
   * Verifica se a interceptação deve ser ignorada para esta requisição
   * @param request A requisição HTTP
   * @param token O token de autenticação
   * @returns true se a interceptação deve ser ignorada, false caso contrário
   */
  private shouldSkipInterception(
    request: HttpRequest<any>,
    token: string | null,
  ): boolean {
    return (
      token === null ||
      request.url.startsWith(`${environment.apiUrl}oauth`) ||
      request.url.startsWith(`${environment.apiUrl}public`)
    );
  }

  /**
   * Constrói os headers para a requisição
   * @param request A requisição HTTP original
   * @param token O token de autenticação
   * @returns Um objeto com os headers a serem adicionados
   */
  private buildHeaders(
    request: HttpRequest<any>,
    token: string,
  ): { [key: string]: string } {
    const headers: { [key: string]: string } = {
      Authorization: `Bearer ${token}`,
    };

    // Usa o Accept específico da chamada original se estiver presente
    const originalAccept = request.headers.get('Accept');
    headers['Accept'] = originalAccept || 'application/json';

    if (!this.isSpecialEndpoint(request)) {
      headers['Content-Type'] = 'application/json';
    }

    return headers;
  }

  /**
   * Verifica se a requisição é para um endpoint especial
   * @param request A requisição HTTP
   * @returns true se for um endpoint especial, false caso contrário
   */
  private isSpecialEndpoint(request: HttpRequest<any>): boolean {
    const specialEndpoints = ['imagem', 'vetor', 'salvarVetorFaces'];
    return specialEndpoints.some((endpoint) => request.url.endsWith(endpoint));
  }
}
