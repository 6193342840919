<mat-sidenav-container autosize class="h-svh">
    <mat-sidenav
        *ngIf="showSidenav && !block"
        #snav
        (openedChange)="aberturaDoMenuAlterada($event)"
        [opened]="!mobileQuery.matches || menuCollapse"
        [mode]="mobileQuery.matches ? 'over' : 'side'"
        [class.menu-collapse]="menuCollapse && !mobileQuery.matches"
        class="h-full !bg-[#072E51]"
    >
        <app-menu
            [collapse]="menuCollapse"
            (collapseChange)="aberturaDoMenuAlterada($event)"
        ></app-menu>
    </mat-sidenav>
    <mat-sidenav-content class="!flex !flex-col !overflow-hidden">
        <app-header-toolbar
            (collapse)="menuCollapse = !menuCollapse"
            [moduloAtivo]="moduloAtivo"
            [block]="block"
            class="!flex-shrink-0"
        ></app-header-toolbar>
        <div class="!flex-grow !overflow-hidden">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
