<div>
  <div class="flex h-8 items-center">
    @if (collapse) {
    <div
      class="mx-4 w-full border-[0.05rem] border-solid border-white/20"
    ></div>
    }

    <span class="ml-4 text-xs uppercase text-slate-500" *ngIf="!collapse">
      {{ grupo.displayName }}
    </span>
  </div>

  <div
    [routerLink]="item.route"
    routerLinkActive="!text-white"
    class="relative flex h-12 w-full cursor-pointer items-center text-slate-400 transition-colors duration-200 ease-in-out hover:bg-black/30 hover:text-slate-200"
    *ngFor="let item of itensDisponiveis"
    [nz-tooltip]="
      item.tooltip ? item.tooltip : collapse ? item.displayName : ''
    "
    nzTooltipPosition="right"
  >
    <!-- <mat-icon class="item-icon" mat-list-icon>{{item.iconName}}</mat-icon> -->

    <i
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.iconName)"
      class="pointer-events-none flex w-[63px] items-center justify-center"
    ></i>

    <span class="mr-5 text-sm font-[300]" *ngIf="!collapse">
      {{ item.displayName }}
    </span>
    @if(item.badge && !collapse) {
    <span
      class="-ml-3 flex h-3 items-center rounded-full bg-blue-700 px-1 pb-0.5 text-2xs tracking-wide text-white"
      >{{ item.badge }}</span
    >
    } @else if (item.badge && collapse) {
    <span
      class="absolute bottom-3.5 right-5 h-2 w-2 rounded-full bg-blue-600"
    ></span>
    }
  </div>
</div>
