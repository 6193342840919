import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { TermoDeUso } from 'src/app/core/model';
import { PrivateService } from 'src/app/private/private.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-termo-nao-assinado',
  templateUrl: './termo-nao-assinado.component.html',
  styleUrls: ['./termo-nao-assinado.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatTooltipModule],
})
export class TermoNaoAssinadoComponent implements OnInit {
  aceitando = false;
  carregando = false;
  termoLido = false;
  termoVigente: TermoDeUso;

  constructor(
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    public privateService: PrivateService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.auth.termoVigente) {
      this.termoVigente = this.auth.termoVigente;
      this.termoVigente.termo = this.termoVigente.termo.replace('<br>', '\n\n');
    } else {
      this.carregando = true;
      this.privateService.getTermoVigente().subscribe(
        (resp) => {
          this.auth.termoVigente = resp;
          this.termoVigente = this.auth.termoVigente;
          this.termoVigente.termo = this.termoVigente.termo.replace(
            '<br>',
            '\n\n',
          );
          this.carregando = false;
        },
        (error) => {
          this.errorHandler.handle(error, 'Erro ao buscar termo vigente');
        },
      );
    }
  }

  confirmaAceite() {
    this.aceitando = true;
    this.privateService.aceitarTermoVigente(this.termoVigente.id).subscribe(
      (resp) => {
        this.auth.termoAssinado = true;
        this.router.navigate(['consultas']);
        this.auth.atualizarToken();
      },
      (error) => {
        this.aceitando = false;
        this.errorHandler.handle(
          error,
          'Erro registrar Autorização de usuário',
        );
      },
    );
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight * 0.98
    ) {
      this.termoLido = true;
    }
  }
}
