<div class="flex h-full flex-col">
    <!-- logo + text -->
    <div
        *ngIf="!mobileQuery.matches"
        class="flex h-[55px] flex-shrink-0 items-center text-white"
    >
        <img
            src="assets/svg/logo-ab-light.svg"
            alt=""
            class="ml-[17px] size-8"
        />

        <!-- text -->
        @if (!collapse) {
            <span class="ml-2 grow font-play text-lg">Alerta Brasil</span>
        }
    </div>
    <div class="flex flex-grow">
        <div class="flex flex-col justify-between">
            <div class="">
                @for (grupo of menu; track grupo.displayName) {
                    <app-menu-list-item
                        [grupo]="grupo"
                        [collapse]="collapse && !mobileQuery.matches"
                        (click)="onItemSelected()"
                    >
                    </app-menu-list-item>
                }
            </div>
            <div
                class="flex h-12 w-full cursor-pointer items-center text-slate-400 transition-colors duration-200 ease-in-out hover:bg-black/30 hover:text-slate-200"
                [nz-tooltip]="collapse ? 'Reportar problema' : ''"
                nzTooltipPosition="right"
                (click)="reportarProblema()"
                (keydown.enter)="reportarProblema()"
                tabindex="0"
            >
                <span class="flex w-[65px] items-center justify-center">
                    <ng-icon name="heroChatBubbleLeftRight" size="24"></ng-icon>
                </span>
                @if (!collapse) {
                    <span class="mr-5 text-sm font-[300]">
                        Reportar um problema
                    </span>
                }
            </div>
        </div>
    </div>
</div>
